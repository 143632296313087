<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
            <v-col cols="12" sm="12" md="6">
              <v-row class="banner-h" align="end">
                <v-col cols="1" class="line-banner pa-1 d-none d-sm-flex">
                </v-col>
                <v-col cols="12" sm="11" class="pa-0">
                  <span class="white-text heading mgt-12px">
                    ATTACKING THE REAL WORLD WITH PYTHON COURSE<br/>
                    <span class="caption">
                      ประยุกต์ใช้ไพธอน กับโลกแห่งความเป็นจริง
                    </span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <!-- <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn> -->
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow">
            <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      v-if="salebox.imgicon"
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                    <v-img
                      v-else-if="!salebox.imgicon && salebox.icon"
                      max-width="30px"
                    >
                      <v-icon right>
                      {{salebox.icon}}
                      </v-icon>
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
              </v-row>
            </v-container>
            <v-responsive :aspect-ratio="16/9" class="pt-6">
              <iframe
                :src="teaserLink"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              >
              </iframe>
            </v-responsive>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px pt-0">
              <div class="pd-24px">
                คือ รายวิชาสำหรับผู้ที่ต้องการสั่งการคอมพิวเตอร์ด้วยภาษา Python
                เพื่อเเก้ปัญหาในเชิงธุรกิจเเละในชีวิตประจำวัน
                โดยคอร์สเรียนนี้จะเน้นการวิเคราะห์เเละการปฏิบัติจริงในเชิงธุรกิจเเละในชีวิตประจำวัน
                <br/><br/>

                <v-img
                  :src="require('../../assets/image/whatattacking.png')"
                ></v-img>
                <br/>

                 <span class="title font-weight-black">เนื้อหาหลักสูตร</span><br/>
                <v-row>
                  <v-col cols="1" class="px-1 text-end">
                    <v-icon>query_builder</v-icon>
                  </v-col>
                  <v-col  cols="11" sm="8" class="px-0">
                    เวลาเรียนรู้ รวมทำโปรเจกต์ และแบบฝึกหัด ประมาณ 10 ชั่วโมง
                  </v-col>
                  <v-col cols="1" class="px-1 text-end">
                    <v-icon>play_circle_outline</v-icon>
                  </v-col>
                  <v-col cols="11" sm="2" class="px-0">
                     16 คลิป
                  </v-col>
                </v-row>

                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(part, index) in courseOutline"
                    :key="index"
                  >
                    <v-expansion-panel-header>
                      <span
                        class="font-weight-medium navy-text"
                      >
                        <v-row class="py-0 my-0">
                          <v-col cols="12" sm="2" class="pa-0 header-content">
                            Part {{part.part}}:
                          </v-col>
                          <v-col cols="12" sm="7" class="pa-0 px-1 header-content">
                              {{part.name}}
                          </v-col>
                          <v-col cols="12" sm="3"
                            class="pa-0 text-sm-end pr-3 header-content"
                          >
                            {{part.chapters.length}} คลิป
                          </v-col>
                        </v-row>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{part.des}}
                      <v-list>
                        <v-list-item
                          v-for="(chap, indexChap) in part.chapters"
                          :key="indexChap"
                        >
                          <v-row class="pa-0 base-text">
                            <v-col class="pa-0 px-1 text-content" cols="1">
                              -
                            </v-col>
                            <v-col cols="11" class="pa-0 px-1 text-content">
                              {{chap}}
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <br/>

                <span class="title font-weight-black">Attacking the Real World with Python
                  เหมาะกับใคร</span><br/>
                เหมาะสำหรับพนักงานองค์กร, ผู้บริหาร,
                บุคคลทั่วไปที่สนใจ หรือผู้ที่ต้องการนำเสนอข้อมูลให้น่าสนใจ
                โดยผู้เรียนควรมีพื้นฐานการเขียนภาษาโปรแกรม Python ในระดับเบื้องต้นบ้างแล้ว
                <br/><br/>

                <span class="title font-weight-black">สิ่งที่คุณจะได้รับ</span><br/>
                การสั่งการคอมพิวเตอร์ด้วยภาษา Python นั้นมีประโยชน์อย่างมาก
                โดยเฉพาะผู้ที่สนใจหรือทำงานเกี่ยวกับการประมวลผลข้อมูล การประมวลผลภาพ
                ปัญญาประดิษฐ์ เเละการเรียนรู้ของเครื่องคอมพิวเตอร์ อีกทั้งภาษา Python
                ยังเป็นภาษาที่มีโครงสร้างไม่ซับซ้อน สามารถเรียนรู้ได้เร็ว
                เหมาะกับผู้ที่เริ่มต้นเขียนโปรเเกรม หรือผู้ที่มีทักษะการเขียนโปรเเกรมด้วยภาษาอื่น ๆ
                อยู่เเล้วเเละต้องการเปลี่ยนมาใช้ภาษา Python<br/>

                <br/>
                <v-img
                  class="d-none d-sm-flex"
                  :src="require('../../assets/image/learningperiod-course-desktop.png')"
                ></v-img>

                <v-img
                  class="d-flex d-sm-none"
                  :src="require('../../assets/image/learningperiod-course-mobile.png')"
                ></v-img>

                <br/><br/>

                <span class="title font-weight-black">อาจารย์ผู้สอน</span><br/>
                <v-row>
                  <v-col cols="12" sm="4"
                    v-for="(instructor, index) in instructors"
                    v-bind:key="index"
                  >
                    <v-card flat>
                      <v-container>
                        <v-row justify="center">
                          <v-img
                            aspect-ratio="1"
                            :src="instructor.img"
                            max-height="150px"
                            max-width="150px"
                          ></v-img>
                        </v-row>
                        <v-row justify="center" class="text-content font-weight-bold navy-text">
                          {{instructor.tth}} {{instructor.fth}} {{instructor.lth}}
                        </v-row>
                        <v-row justify="center" class="text-center prof font-weight-light">
                          {{instructor.ten}} {{instructor.fen}} {{instructor.len}}<br/>
                          {{instructor.dep}}<br/>
                          {{instructor.fac}}<br/>
                          จุฬาลงกรณ์มหาวิทยาลัย
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
                <br/>

                <span class="title font-weight-black">FAQ</span><br/><br/>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(item,i) in faqs"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <span class="header-content font-weight-bold navy-text">{{item.q}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="text-content">{{item.a}}</span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br/><br/>

                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">Achieve Plus</a><br/><br/>
              </div>

            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-responsive :aspect-ratio="16/9" class="pt-6">
              <iframe
                :src="teaserLink"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              >
              </iframe>
            </v-responsive>
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <!-- <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn> -->
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        v-if="salebox.imgicon"
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                      <v-img
                        v-else-if="!salebox.imgicon && salebox.icon"
                        max-width="30px"
                      >
                        <v-icon right>
                        {{salebox.icon}}
                        </v-icon>
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'pathwayDetail',
  props: ['pathwayId'],
  data() {
    return {
      card_text: '',
      sku: 'pw0040021',
      teaserLink: 'https://www.youtube.com/embed/aN1N0zC72vc?rel=0&modestbranding=1',
      salebox: [
        {
          title: 'วิชา (course)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'เรียนได้ทันทีเมื่อชำระเงิน', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '3 เดือน', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'เรียนออนไลน์', imgicon: require('@/assets/image/icon-14.svg'), id: 4,
        },
        {
          title: 'ระดับกลาง (Intermediate Level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          blank: true,
        },
        {
          title: '10 ชั่วโมง วิดีโอสั้น เข้าใจง่าย', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-04.svg'), id: 6,
        },
        {
          title: '1 รายวิชา 8 บทเรียน 16 คลิป', id: 7,
        },
        {
          title: '4,500 บาท', icon: 'local_offer', id: 8,
        },
        // {
        //   title: 'Achieve+ Member รับส่วนลด 25%',
        // link: '/member', icon: 'fas fa-star',
        // imgicon: require('@/assets/image/icon-13.svg'), id: 9,
        // },
      ],
      courseOutline: [
        {
          part: 1,
          name: 'Using Monte Carlo to find PI',
          des: 'เรียนรู้วิธีการทางสถิติ ที่ใช้สร้างตัวเลขสุ่ม เพื่อหาคำตอบของปัญหา โดยเราจะสอนการใช้ Loop เพื่อสุ่มการสร้างจุด เเละสอนการใช้ if เพื่อตรวจสอบว่าจุดอยู่ในวงกลมหรือไม่',
          chapters: [
            'วิเคราะห์ Using Monte Carlo to find PI',
            'ฝึกปฏิบัติ Using Monte Carlo to find PI',
          ],
        },
        {
          part: 2,
          name: 'OpenAI Gym',
          des: 'เรียนรู้การใช้ Gym ที่เป็น Library เพื่อให้คนเข้ามาทดลองใช้ Algorithm ในการเขียนโปรเกรม เพื่อเเก้ปัญหาเชิงธุรกิจเเละชีวิตประจำวันต่างๆ เช่น การควบคุมรถเข็น โดยเราจะสอนการใช้ tuple ในการเก็บข้อมูล สอนการใช้ while loop ตามเงื่อนไข เเละสอนการใช้ if ตรวจสอบว่าถึงเงื่อนไขเเล้วหรือยัง',
          chapters: [
            'วิเคราะห์ OpenAI Gym',
            'ฝึกปฏิบัติ Gym',
          ],
        },
        {
          part: 3,
          name: 'Risk Management',
          des: 'เรียนรู้การกำหนดนโยบายต่างๆ เพื่อใช้กับคนจำนวนมาก ที่อาจส่งผลกระทบต่อประสิทธิภาพการทำงานของเเต่ละบุคคล ซึ่ง Python ช่วยเเก้ปัญหานี้ได้ โดยเราจะสอนหาเงื่อนไขที่เหมาะสมที่สุดในการดึงคนหรือ unit มาทำงานให้ส่งผลกำไรมากที่สุด',
          chapters: [
            'วิเคราะห์ Risk Management',
            'ฝึกปฏิบัติ Risk Management',
          ],
        },
        {
          part: 4,
          name: 'Highest PM2.5',
          des: 'เรียนรู้การหาช่วงวันเเละเวลาที่มีค่าฝุ่น PM 2.5 สูงสุด ผ่านการนำข้อมูลมาจากกรมควบคุมมลพิษ โดยเราจะสอนการใช้ string กับ file เพื่อเปิดข้อความ ประมวลผมข้อความ สอนการใช้ loop เพื่ออ่านข้อความทั้งหมด เเละสอนการใช้ if เพื่อหาค่าสูงสุด',
          chapters: [
            'วิเคราะห์ Highest PM2.5',
            'ฝึกปฏิบัติ Highest PM2.5',
          ],
        },
        {
          part: 5,
          name: 'Mini Google',
          des: 'เรียนรู้การทำงานของ search engine เเละดึงข้อความจากหน้าเพจต่างๆ ไม่ว่าจะเป็น ข้อความหน้าเว็บที่หน้าสนใจ ตรวจว่ามีเว็บใดเก็บข้อความที่น่าสนใจอยู่บ้าง เเละเเสดงน่าเว็บพร้อมกับข้อความที่น่าสนใจนั้นๆ โดยเราจะสอนการใช้ library requests เเละ BeautifulSoup เพื่ออ่านข้อมูลจาก url เเละประมวล html อย่างง่าย สอนการใช้ list เพื่อเก็บข้อมูลหลายอันไว้ในตัวเเปลเดียว สอนใช้ loop เพื่อประมวลผลข้อมูลในลิสต์',
          chapters: [
            'วิเคราะห์ Mini Google',
            'ฝึกปฏิบัติ Mini Google',
          ],
        },
        {
          part: 6,
          name: 'Visualize a Perceptron',
          des: 'เรียนรู้การทำงานของ Perceptron ที่อยู่ใน Neural Network โดยอาศัย Algorithm หลายตัวในการทำงานพร้อมกันเป็นเครือข่าย โดยเราจะสอนการใช้ Library numpy เพื่อประมวล matrix สอนการใช้ Library matplotlib เพื่อพล็อตกราฟ เเละสอนการใช้ if เพื่อตรวจสอบเงื่อนไขการปรับค่าน้ำหนัก',
          chapters: [
            'วิเคราะห์การ Visualize a Perceptron',
            'ฝึกปฏิบัติการ Visualize a Perceptron',
          ],
        },
        {
          part: 7,
          name: 'Analyze Simple Data from Kaggle',
          des: 'เรียนรู้ Kaggle เเหล่งรวบรวมข้อมูลจำนวนมากเพื่อวิเคราะห์ข้อมูล ซึ่งข้อมูลที่เราเลือกมาสอนนั้นคือ ข้อมูล titanic โดยเราจะสอนการใช้ Library Pandas เพื่ออ่าน เก็บ เเละเเสดงผลข้อมูล สอนการใช้ Library Matplotlib เพื่อพล็อตกราฟ เเละสอนการใช้ Library google.colab ในการอัพโหลดเเละดาวน์โหลดไฟล์',
          chapters: [
            'วิเคราะห์ Analyze Simple Data from Kaggle',
            'ฝึกปฏิบัติ Analyze Simple Data from Kaggle',
          ],
        },
        {
          part: 8,
          name: 'Data Visualization Using Seaborn',
          des: 'เรียนรู้การใช้ Seaborn library ร่วมกับ Pandas ในการเเสดงผล เพื่อนำมาสร้าง bar chart จากข้อมูล titanic โดยเราจะสอนการใช้ Library Pandas เพื่ออ่าน เก็บ เเละเเสดงผลข้อมูล สอนการใช้ Library Matplotlib เพื่อพล็อตกราฟ เเละสอนการใช้ Library google.colab ในการอัพโหลดเเละดาวน์โหลดไฟล์w',
          chapters: [
            'วิเคราะห์ Data Visualization Using Seaborn',
            'ฝึกปฏิบัติ Data Visualization Using Seaborn',
          ],
        },
      ],
      instructors: [
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-03.png'),
          tth: 'ผศ.ดร.',
          fth: 'สุกรี',
          lth: 'สินธุภิญโญ',
          ten: 'Asst.Prof.Dr.',
          fen: 'Sukree',
          len: 'Sinthupinyo',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
      ],
      faqs: [
        {
          q: 'Attacking the Real World with Python คืออะไร สำคัญอย่างไร',
          a: 'คือ รายวิชาสำหรับผู้ที่ต้องการสั่งการคอมพิวเตอร์ด้วยภาษา Python เพื่อเเก้ปัญหาในเชิงธุรกิจเเละในชีวิตประจำวัน โดยคอร์สเรียนนี้จะเน้นการวิเคราะห์เเละการปฏิบัติจริงในเชิงธุรกิจเเละในชีวิตประจำวัน เพื่อช่วยให้ผู้เรียนเข้าใจถึงที่มาเเละหลักการของปัญหาในโปรเจกต์นั้น ๆ เเละนำมาลองปฏิบัติจริงผ่านการใช้เครื่องมือพื้นฐานและไลบรารี (Library) ต่าง ๆ ในภาษา Python ได้',
        },
        {
          q: 'Attacking the Real World with Python เหมาะกับใคร',
          a: 'เหมาะสำหรับพนักงานองค์กร, ผู้บริหาร, บุคคลทั่วไปที่สนใจ หรือผู้ที่ต้องการนำเสนอข้อมูลให้น่าสนใจ โดยผู้เรียนควรมีพื้นฐานการเขียนภาษาโปรแกรม Python ในระดับเบื้องต้นบ้างแล้ว',
        },
        {
          q: 'เนื้อหาวิชานี้สามารถนำไปใช้ในสถานการณ์ใดได้บ้าง',
          a: 'ประมวลผลข้อมูลที่มีจำนวนมากให้เป็นกราฟต่าง ๆ ได้, พัฒนาทักษะการเขียนโปรเเกรมด้วยภาษาไพธอนจากกรณีศึกษา, ผู้ที่สนใจในการทำงานเกี่ยวกับการประมวลผลข้อมูล การประมวลผลภาพ ปัญญาประดิษฐ์ การเรียนรู้ของเครื่อง, สามารถนำกรณีศึกษารูปแบบต่าง ๆ จากบทเรียน มาประยุกต์ใช้กับพัฒนาระบบงานเชิงธุรกิจได้, สามารถต่อยอดไปในด้านการจัดการ จัดเก็บ ประมวลผลและวิเคราะห์ข้อมูล เพื่ออธิบายและหาองค์ความรู้จากข้อมูล',
        },
        {
          q: 'Certification พิเศษอย่างไร',
          a: 'เป็น Institute Certificate ที่ได้รับการรับรองจากจุฬาลงกรณ์มหาวิทยาลัยซึ่งเป็นสถาบันการศึกษาชั้นนำของประเทศ',
        },
        {
          q: 'หากมีคำถามหรือข้อสงสัยระหว่างเรียน สามารถติดต่อได้ทางช่องทางไหนได้บ้าง',
          a: 'ผ่าน Facebook Closed Group สำหรับผู้เรียนโดยเฉพาะโดยมี PA (Pathway Assistant) เป็นผู้ดูแลให้ความช่วยเหลือ รวมถึงจัดกิจกรรมเสริมสร้างการเรียนรู้ ไม่ว่าจะเป็นการตอบข้อสงสัยเกี่ยวกับบทเรียน การให้เอกสารหรือกรณีศึกษาเพิ่มเติมประกอบวิชา เป็นต้น',
        },
        {
          q: 'ต้องมีความรู้พื้นฐานอะไรมาก่อนบ้างจึงจะสามารถจะเรียนได้',
          a: 'ผู้เรียนควรมีพื้นฐานการเขียนภาษาโปรแกรม Python ในระดับเบื้องต้นเเละสามารถใช้ Google Colab ขั้นพื้นฐานได้',
        },
        {
          q: 'ในรายวิชานี้ จะได้รู้จักกับไลบรารี (Library) อะไรบ้าง',
          a: 'Numpy, Gym, Requests, Beautiful Soup, PythaiNLP, Pandas, Matplotlib, เเละ Seaborn',
        },
        {
          q: 'ควรรู้จักเครื่องมืออะไรบ้าง เพื่อใช้ในการเรียน',
          a: 'Google Colab',
        },
      ],
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-h {
   height: 200px;
}
.banner-bg{
  background-image: linear-gradient(90deg, #003a70, hsla(0, 100%, 100%, 0) 10%, #003a70 96%),
   url('../../assets/image/attcking-cover.jpg');
  background-position: center;
  background-size: cover;
  height: 250px;

  /* margin-left: -600px;
  margin-right: -600px; */
}
.line-banner {
  border: none;
  border-left: 0.4rem solid #fff;
  height: 6rem;
  width: 0.5rem;
  max-inline-size: min-content;
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.prof {
  line-height: 20px;
  font-size: 0.75rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
